import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import '../assets/slick.scss';
import '../assets/slick-theme.scss';
import ProductTile from './productTile/ProductTile';
import LeftArrow from './customArrows/LeftArrow';
import RightArrow from './customArrows/RightArrow';
import styles from './ProductSetCarousel.module.scss';
import './ProductSetCarousel.scss';
import { connect, shallowEqual, useDispatch } from 'react-redux';
import { requestProductSet } from '../behaviour/actions';
import { throttle } from 'lodash';
import {
  useProductDefaultImages,
  parseSortOption,
  useLoadEffect,
  useHasAbilities,
  useMatchMediaContext,
} from 'sana/utils';
import { getDefaultProductList, getPageInfo, getStyles  } from '../utils/constants';
import { ignoreDuplicate } from '../utils/helpers';
import classNames from 'classnames';
import { AbilityTo } from 'sana/constants';
import { SimpleText } from 'sana/texts';
import { DomHead } from 'sana/elements';

const ProductSetCarousel = ({
  model,
  id,
  productSet,
  requestProductSet,
  expired,
}) => {
  const modelRef = useRef();
  let modelExpired = false;
  if (modelRef.current && !shallowEqual(modelRef.current, model))
    modelExpired = true;

  modelRef.current = model;
  const {
    previousNavigatorIcon,
    nextNavigatorIcon,
    productSetValue,
    sortOption,
  } = modelRef.current;

  const rightArrowId = `${id}-right-arrow`;
  const leftArrowId = `${id}-left-arrow`;
  const [canViewCatalog] = useHasAbilities(AbilityTo.ViewCatalog);
  const { medium } = useProductDefaultImages();
  const mainComponentRef = useRef(null);
  const [sliderRef, setSliderRef] = useState(null);
  const pageInfo = getPageInfo(useMatchMediaContext(), modelRef.current);
  const dispatch = useDispatch();
  const [productList, setProductList] = useState([]);
  const [isNextClicked, setIsNextClicked] = useState(false);
  const [changeItemCount, setChangeItemCount] = useState(false);
  const [firstProductIndex, setFirstProductIndex] = useState(0);
  const [sliding, setSliding] = useState(false);

  const refLeftNav = useRef();
  const refRightNav = useRef();
  const refsProductTitles = useRef([]);

  const [pageMeta, setPageMeta] = useState({
    currentPage: 0,
    nextPage: 1,
    numberOfPage: 0,
    perPageItem: pageInfo.itemPerRow * 2,
    totalCount: 0,
    isLoading: false,
  });

  const [sliderMeta, setSliderMeta] = useState({
    index: 0,
    disabledNext: false,
    disabledPrev: true,
    currentSlide: 0,
    hideNavigation: false,
  });

  const navigationIconStatus = slidValue => {
    let disabledPrev,
      disabledNext = false;
      setSliding(false);
    if (slidValue <= 0)
      disabledPrev = true;

    if (productSet?.products?.totalCount <= slidValue + pageInfo.itemPerRow)
      disabledNext = true;
    setSliderMeta({
      ...sliderMeta,
      disabledPrev,
      disabledNext,
    });
    if (isNextClicked)
      nextAction();
    else
      prevAction();
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: pageInfo.itemPerRow,
    slidesToScroll: pageInfo.itemPerRow,
    afterChange: current => navigationIconStatus(current),
    beforeChange: () => setSliding(true),
  };

  useLoadEffect(() => {
    if (!canViewCatalog)
      return;
    if (!productSet || expired || modelExpired || changeItemCount){
      resetStates();
      setChangeItemCount(false);
      requestPageData(
        id,
        productSetValue,
        sortOption,
        pageInfo.itemPerRow * 2,
        0,
        true,
      );
    }
  }, [productSet, canViewCatalog, expired, modelExpired, changeItemCount]);

  useLoadEffect(()=>{
    setChangeItemCount(true);
  },[ pageInfo && pageInfo.itemPerRow ]);

  useLoadEffect(()=> {
    if(expired) {
      resetStates();
      setTimeout(() => {
        setChangeItemCount(true);
      }, 200);
    }
  },[ expired ]);

  const focusToLeftNavigation = () => {
    refLeftNav.current.focus();
  };

  const focusToRightNavigation = () => {
    refRightNav.current.focus();
  };

  const renderSlider = productList => {
    let element = [];
    for (let index = sliderMeta.index; index < productList.length; index++) {
      element = element.concat(
        <ProductTile 
          key={index}
          ref={element => refsProductTitles.current[index] = element}
          contentId={id}
          setFocusToRightButton={focusToRightNavigation}
          setFocusToLeftButton={focusToLeftNavigation}
          isLastItem={(index+1)%pageMeta.perPageItem===0 || productList.length === (index+1)}
          isFirstItem={index%pageMeta.perPageItem===0}
          isFocusable={index>=firstProductIndex}
          product={productList[index]}
          showRatings={modelRef.current.showRatings}
          noImage={medium}
          sliding={sliding}
        />,
      );
    }
    return element;
  };

  const slideList = useMemo(() => {
    return renderSlider(!productList?.length ? productSet ? productSet.products.products : [] : productList);
  }, [productSet, productList]);

  useLoadEffect(() => {
    if (productSet) {
      setPageMeta({
        ...pageMeta,
        totalCount: productSet.products.totalCount,
        numberOfPage: Math.round(
          parseInt(productSet.products.totalCount) / pageInfo.itemPerRow,
        ),
        perPageItem: pageInfo.itemPerRow,
      });
      setSliderMeta({
        ...sliderMeta,
        currentSlide: pageMeta.currentPage === 0 ? settings.slidesToShow : parseInt(sliderMeta.currentSlide + settings.slidesToShow),
        disabledPrev: false,
        hideNavigation: pageInfo.itemPerRow >= pageMeta.totalCount,
      });
      const exitingProduct = productList.filter(item => item.id !== undefined);
      setProductList(() => ignoreDuplicate(exitingProduct.concat(productSet.products.products)));
    }
  }, [productSet]);

  useEffect(() => {
     let disabledNext,
      disabledPrev = false;
    if (pageMeta.totalCount <= settings.slidesToShow) {
      disabledNext = true;
      disabledPrev = true;
    } else if (sliderMeta.currentSlide >= pageMeta.totalCount)
      disabledNext = true;
    else if (sliderMeta.currentSlide === settings.slidesToShow || pageMeta.currentPage === 0)
      disabledPrev = true;

    setSliderMeta({
      ...sliderMeta,
      disabledNext,
      disabledPrev,
      hideNavigation: pageInfo.itemPerRow >= pageMeta.totalCount,
    });
  }, [sliderMeta.currentSlide, pageMeta]);

  useEffect(() => {
    setPageMeta({
      ...pageMeta,
      isLoading: false,
    });
    renderSlider(productList);
  }, [productList]);

  useLoadEffect(() => {
    if (productList.length > 0) {
    setTimeout(() => {
      refsProductTitles.current[firstProductIndex] && refsProductTitles.current[firstProductIndex].title.focus();
    }, 500);
    }
  }, [firstProductIndex]);

  const resetStates = useCallback(() => {
    setProductList(getDefaultProductList(pageInfo.itemPerRow));
    setPageMeta({
      currentPage: 0,
      nextPage: 1,
      numberOfPage: 0,
      perPageItem: pageInfo.itemPerRow * 2,
      totalCount: 0,
      isLoading: false,
    });
    setSliderMeta({
      index: 0,
      disabledNext: false,
      disabledPrev: true,
      currentSlide: 0,
      hideNavigation: false,
    });
    setFirstProductIndex(0);
  }, []);

  const requestPageData = useCallback(
    (id, productSetValue, sortOption, perPageItem, page, init = false) => {
      const sorting = sortOption ? [parseSortOption(sortOption)] : [];
      if (page >= 1)
        page = page + 1;

      dispatch(
        requestProductSet(id, productSetValue, sorting, perPageItem, page, modelRef.current.showRatings, modelRef.current.imagesChangeByHovering),
      );
     
      if (init) {
        setProductList(productList =>
          productList.concat(getDefaultProductList(pageInfo.itemPerRow)),
        );
      }
    },
    [modelExpired, pageMeta],
  );

  const nextAction = throttle(() => {
    if ( productList.length - sliderMeta.currentSlide === settings.slidesToShow) {
      if (pageMeta.currentPage !== pageMeta.numberOfPage) {
        const nextPage = pageMeta.nextPage + 1;
        setPageMeta({
          ...pageMeta,
          nextPage,
          currentPage: pageMeta.nextPage,
          isLoading: true,
        });
      }
      requestPageData(
        id,
        productSetValue,
        sortOption,
        settings.slidesToShow,
        pageMeta.nextPage,
      );
    } else {
      setSliderMeta({
        ...sliderMeta,
        currentSlide: parseInt(sliderMeta.currentSlide + settings.slidesToShow),
        disabledPrev: false,
      });
    }
    setFirstProductIndex(firstProductIndex+settings.slidesToShow);
  }, 1000);

  const prevAction = throttle(() => {
    setSliderMeta({
      ...sliderMeta,
      currentSlide: parseInt(sliderMeta.currentSlide - settings.slidesToShow),
      disabledPrev: false,
    });
  }, 1000);

  const onKeyDownRightArrow = event=>{
    if (event?.shiftKey && event?.key === 'Tab' ) {
      event.preventDefault();
      setTimeout(() => {
        const index = (firstProductIndex + pageInfo.itemPerRow) - 1;
        refsProductTitles.current[index] && refsProductTitles.current[index].orderButton.focus();
        !refsProductTitles.current[index] && refsProductTitles.current[refsProductTitles.current.length-1].orderButton.focus();
      }, 100);
    }
    else if(['Enter', ' '].includes(event?.key)){
      onClickRightArrow();
    }
  };

  const onKeyDownLeftArrow = event=>{
    if (event?.shiftKey && event?.key === 'Tab') {
      mainComponentRef.current.focus();
    }
    else if(event?.key === 'Tab'){
     setTimeout(() => {
      refsProductTitles.current[firstProductIndex] && refsProductTitles.current[firstProductIndex].title.focus();
     }, 100);
    }
    else if(['Enter', ' '].includes(event?.key)){
      onClickLeftArrow();
    }
  };

  const onClickRightArrow = event => {
    if (!event || sliderMeta.disabledNext || pageMeta.isLoading)
      return;
    sliderRef.slickNext();
    setIsNextClicked(true);
  };

  const onClickLeftArrow = event => {
    if(!event || sliderMeta.disabledPrev)
      return;
    sliderRef.slickPrev();
    setIsNextClicked(false);
    setFirstProductIndex(firstProductIndex-pageInfo.itemPerRow);
  };
   
  if(!productSet || !productSet.products)
    return null;

  return (
    <>
      <DomHead content={getStyles(id, model)} />
      <div
        id={`product-set-carousel-${id}`}
        className={classNames(
          'product-set-carousel-content-block',
          styles.root,
        )}
        ref={mainComponentRef}
      >
        <span role="presentation"
          tabIndex={0}
          className={styles.visuallyHidden}
        >
          {model.contentElementAltText ? model.contentElementAltText : <SimpleText textKey={'ContentElementAltText'} />}
        </span>
        <div className={styles.sliderContent} tabIndex={-1}>
          <div className={styles.navigation} tabIndex={-1}>
            {!sliderMeta.hideNavigation && (
              <LeftArrow
                id={leftArrowId}
                ref={refLeftNav}
                imagePath={previousNavigatorIcon}
                onClick={onClickLeftArrow}
                onKeyDown={onKeyDownLeftArrow}
                disabled={sliderMeta.disabledPrev}
              />
            )}
          </div>
          <div id={`${id}-slider-container`} tabIndex={-1}>
            {
              !changeItemCount && <Slider ref={c => setSliderRef(c)} {...settings}>
                {slideList}
              </Slider>
            }
          </div>
          <div className={styles.navigation} tabIndex={-1}>
            {!sliderMeta.hideNavigation && (
              <RightArrow
                id={rightArrowId}
                ref={refRightNav}
                imagePath={nextNavigatorIcon}
                disabled={sliderMeta.disabledNext}
                apiLoading={pageMeta.isLoading}
                onKeyDown={onKeyDownRightArrow}
                onClick={onClickRightArrow}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

ProductSetCarousel.propTypes = {
  model: PropTypes.shape({
    previousNavigatorIcon: PropTypes.string,
    nextNavigatorIcon: PropTypes.string,
    productSetValue: PropTypes.string,
    sortOption: PropTypes.string,
    contentElementAltText: PropTypes.string,
    productsOnExtraLargeScreen: PropTypes.number,
    productsOnExtraSmallScreen: PropTypes.number,
    productsOnLargeScreen: PropTypes.number,
    productsOnMediumScreen: PropTypes.number,
    productsOnSmallScreen: PropTypes.number,
  }),
  id: PropTypes.string.isRequired,
  productSet: PropTypes.object,
  expired: PropTypes.bool,
  requestProductSet: PropTypes.func.isRequired,
};

const mapStateToProps = ({ items }, { id }) => {
  const productSet = items && items[id];
  if (!productSet)
    return { productSet: undefined, expired: undefined };

    return {
    productSet,
    expired: productSet.expired,
  };
};

export default connect(mapStateToProps, { requestProductSet })(ProductSetCarousel);
