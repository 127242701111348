export const PRODUCT_SET_REQUESTED =
  'PRODUCT_SET_CAROUSAL/PRODUCT_SET_REQUESTED';
export const requestProductSet = (
  contentBlockId,
  productSetId,
  sorting,
  size, 
  index, 
  reviews = false,
  imagesChangeByHovering = false,
) => ({
  type: PRODUCT_SET_REQUESTED,
  payload: { contentBlockId, productSetId, sorting ,size, index ,reviews, imagesChangeByHovering },
});

export const PRODUCT_SET_RECEIVED = 'PRODUCT_SET_CAROUSAL/PRODUCT_SET_RECEIVED';
export const productSetReceived = (contentBlockId, products) => ({
  type: PRODUCT_SET_RECEIVED,
  payload: { contentBlockId, products },
});