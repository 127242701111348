import React from 'react';
import PropTypes from 'prop-types';
import styles from '../ProductSetCarousel.module.scss';
import { urlEscape } from '../../utils/helpers';
import classNames from 'classnames';

const RightArrow = React.forwardRef(({ id,imagePath, onClick, disabled, apiLoading, onKeyDown }, ref) => {
  return (
    <button
      id={id}
      ref={ref}
      className={classNames('product-set-carousel-next',styles.next)} 
      onClick={!disabled ? onClick : undefined}
      tabIndex={0}
      onKeyDown={onKeyDown}
      aria-disabled={disabled || apiLoading}
      type="button"
      aria-label="Right arrow"
    > 
      {apiLoading === true ?  
        <div className={styles.loader} /> : (
      <img
        src={urlEscape(imagePath)}
        className={classNames(styles.navigationIcon, disabled ? styles.btnDisabled  : '')}
        alt=""
        aria-hidden
      />
    )}
    </button>
  );
});

RightArrow.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  imagePath: PropTypes.string,
  disabled:PropTypes.bool,
  apiLoading:PropTypes.bool,
};

export default RightArrow;
